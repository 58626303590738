@import url('https://fonts.googleapis.com/css?family=Heebo|Roboto+Mono&display=swap');

html, body {
  background-color: #1E1D22;
  font-size: 14px;
}
#root {
  height: inherit;
}

::selection {
  background-color: rgb(239,124,0) !important;
  color: black !important;
}
